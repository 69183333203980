import styled from "styled-components"

export const CardFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const CardTitle = styled.p`
  font-size: var(--font-size-h4);
  font-family: var(--font-primary);
  font-weight: 500;
  a {
    color: inherit;
  }
`
