import * as React from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Link, navigate } from "gatsby"

import Card, { CardBody } from "../Card"
import { CardFooter, CardTitle } from "./style"
import { formatDate } from "../../utils"

export default function BlogCard({
  slug,
  title,
  hero,
  excerpt,
  date,
  tags,
  ...rest
}) {
  return (
    <Card {...rest}>
      <GatsbyImage
        image={getImage(hero.src.localFile)}
        alt={title}
        onClick={() => navigate(slug)}
        objectFit="cover"
        style={{ cursor: "pointer" }}
      />
      <CardBody {...rest}>
        <CardTitle>
          <Link to={slug}>{title}</Link>
        </CardTitle>
        <p>{excerpt}</p>
        <CardFooter>
          <p>{formatDate(date)}</p>
          <Link to={slug}>
            <p>Read More &nbsp;&#x2794;</p>
          </Link>
        </CardFooter>
      </CardBody>
    </Card>
  )
}
