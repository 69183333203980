import * as React from "react"
import { graphql } from "gatsby"

import Footer from "../components/Footer"
import { Container, Section } from "../components/global"
import Grid from "../components/Grid"
import Layout from "../components/Layout"
import Navbar from "../components/Navbar"
import BlogCard from "../components/BlogCard"
import SEOHead from "../components/SEOHead"
import { getSrc } from "gatsby-plugin-image"
import moment from "dayjs"

export default function Blog({
  data: {
    allGraphCmsBlogPost: { blogPosts },
  },
}) {
  const articles = blogPosts.sort((first, second) =>
    moment(second.date).diff(moment(first.date))
  )

  const BlogPostGrid = () => (
    <Grid style={{ "--gap": "2rem", "--auto-grid-min-size": "300px" }}>
      {articles.map(node => (
        <BlogCard
          {...node}
          key={node.slug}
          style={{ "--card-body-gap": "1.5em" }}
        />
      ))}
    </Grid>
  )
  return (
    <Layout>
      <SEOHead
        title="Writings"
        description="A collection of articles written by Vinayaka K V"
        image={getSrc(articles[0].hero.src.localFile)}
      />
      <Navbar />
      <Container>
        <Section>
          <h1>Writings</h1>
          <BlogPostGrid />
        </Section>
      </Container>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  {
    allGraphCmsBlogPost {
      blogPosts: nodes {
        slug
        title
        date
        excerpt
        hero {
          src {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
